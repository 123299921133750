<template>
  <v-radio-group :value="value" @change="onUpdateValue" :disabled="isReadSurvey || options.readonly">
    <v-radio v-for="(choice, index) in choices"
             :key="`${questionId}-${index}`"
             :label="choice.label"
             :value="choice.value"
    />
  </v-radio-group>
</template>

<script>
import questionMixins from '@/components/Survey/Reader/questionMixins'

export default {
  name: 'SurveyQuestionChoice',

  mixins: [questionMixins],
}
</script>

<style lang="scss" scoped>
article {
  display: flex;
  flex-direction: column;

  .v-input::v-deep {
    label {
      color: black;
    }
  }
}
</style>
